import { DomainError } from './DomainError';

export class EmptyFieldError extends DomainError {
  private _fieldName: string = '';

  constructor (fieldName: string) {
    super('You must enter your email');
    this._fieldName = fieldName;
  }

  get fieldName (): string {
    return this._fieldName;
  }
}

import { SurveysService } from 'core/domain/SurveysService';
import { Survey } from 'core/domain/Survey';

export default class GetSurveys {
  private service: SurveysService;

  constructor (service: SurveysService) {
    this.service = service;
  }

  async execute (screen: string): Promise<Survey[]> {
    return this.service.getSurveys(screen);
  }
}

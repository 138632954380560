export class ArrayFormatter {
  static removeOrAddElement (elements: any[], element: any): any[] {
    const elementsClone = [...elements];
    if (elementsClone.includes(element)) return this.removeString(elementsClone, element);
    return this.addString(elementsClone, element);
  }

  static removeString (elements: any[], element: any): any[] {
    const removedElementArray = elements.filter((item) => item !== element);
    return removedElementArray;
  }

  static addString (elements: any[], element: any): any[] {
    elements.push(element);
    return elements;
  }
}

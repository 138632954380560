import React, { Dispatch, SetStateAction } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import InputText from '../components/Form/InputText';
import RightMenu from '../components/Form/RightMenu';
import LanguageSelector from '../components/Form/LanguageSelector';
import Description from '../components/Form/Description';
import Question from '../components/Form/Question';
import { descriptionData, titleData } from '../../language/InputText';
import { QuestionType } from '../../utils/SurveyDataEncoder/QuestionType';
import { InputType } from '../../utils/SurveyDataEncoder/InputType';
import './style.css';

interface CESurveyUIProps {
  languages: string[];
  setLanguages: Dispatch<SetStateAction<string[]>>
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
  segmentation: string[];
  setSegmentation: Dispatch<SetStateAction<string[]>>;
  mandatory: boolean;
  setMandatory: Dispatch<SetStateAction<boolean>>;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  redirection: string;
  setRedirection: Dispatch<SetStateAction<string>>;
  questions: QuestionType[];
  setQuestions: Dispatch<SetStateAction<QuestionType[]>>
  title: InputType;
  setTitle: Dispatch<SetStateAction<InputType>>
  description: InputType;
  setDescription: Dispatch<SetStateAction<InputType>>
  onSaveSurvey: () => void;
  surveySaved: boolean;
  editMode: boolean;
  isLoading: boolean;
  screen: string;
  setScreen: Dispatch<SetStateAction<string>>
}

const CESurveyUI: React.FC<CESurveyUIProps> = ({ languages, setLanguages, selectedLanguage, setSelectedLanguage, segmentation, setSegmentation, mandatory, setMandatory, redirection, setRedirection, questions, setQuestions, title, setTitle, description, setDescription, onSaveSurvey, active, setActive, surveySaved, editMode, isLoading, screen, setScreen }) => {
  return (
    <>
      <h2><Link to={'/'} className="link"><ArrowLeft /></Link> { editMode ? 'Edit Survey' : 'Create Survey' }</h2>
      {surveySaved && <Alert variant="success">
        { editMode ? 'Survey successfully updated' : 'Survey successfully saved' }
      </Alert>}
      <Row>
        <Col xs="4" className="right-column">
          <RightMenu
            languages={languages}
            setLanguages={setLanguages}
            segmentation={segmentation}
            setSegmentation={setSegmentation}
            mandatory={mandatory}
            setMandatory={setMandatory}
            redirection={redirection}
            setRedirection={setRedirection}
            questions={questions}
            setQuestions={setQuestions}
            onSaveSurvey={onSaveSurvey}
            active={active}
            setActive={setActive}
            isLoading={isLoading}
            screen={screen}
            setScreen={setScreen}
          />
        </Col>
        <Col xs="8">
          <LanguageSelector selectedLanguage={selectedLanguage} languages={languages} setSelectedLanguage={setSelectedLanguage} />
          <div id="scroll-questions-content" className="scroll-questions-content">
            <InputText selectedLanguage={selectedLanguage} dataType={titleData} showTitle label={title} setLabel={setTitle} />
            <Description selectedLanguage={selectedLanguage} data={descriptionData} description={description} setDescription={setDescription} />
            {questions.map((question: QuestionType, index: number) => <Question key={index} questionNumber={index + 1} selectedLanguage={selectedLanguage} question={question} setQuestions={setQuestions} questions={questions} />)}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CESurveyUI;

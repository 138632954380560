import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { QuestionType } from 'ui/utils/SurveyDataEncoder/QuestionType';
import { InputType } from 'ui/utils/SurveyDataEncoder/InputType';
import QuestionUI from './ui';
import './style.css';

interface QuestionProps {
  selectedLanguage: string;
  question: QuestionType;
  questionNumber: number;
  questions: QuestionType[];
  setQuestions: Dispatch<SetStateAction<QuestionType[]>>
}

const Question: React.FC<QuestionProps> = ({ selectedLanguage, questionNumber, question, setQuestions, questions }) => {
  const [questionTitle, setQuestionTitle] = useState<InputType>(question.question);
  const [multipleAnswer, setMultipleAnswer] = useState<boolean>(question.allows_multiple_answers);
  const [answers, setAnswers] = useState<InputType[]>([]);

  useEffect(() => {
    setQuestionTitle(question.question);
    setAnswers(question.possible_answers);
  }, []);

  const addAnswer = () => {
    const answersClone: InputType[] = [...answers];
    answersClone.push({ en: '', es: '', po: '' });
    setAnswers(answersClone);
  };

  const removeAnswer = (index: number) => {
    const answersClone = [...answers];
    answersClone.splice(index, 1);
    setAnswers(answersClone);
  };

  const updateQuestions = (multiple: boolean) => {
    let newQuestion = getNewQuestion(multiple);
    const questionsCopy = [...questions];
    questionsCopy[questionNumber - 1] = newQuestion;
    setQuestions(questionsCopy);
  };

  const getNewQuestion = (multiple: boolean): QuestionType => {
    return {
      question: questionTitle,
      possible_answers: question.possible_answers,
      allows_multiple_answers: multiple
    };
  };

  const removeQuestion = (index: number) => {
    const questionsClone = [...questions];
    questionsClone.splice(index, 1);
    setQuestions(questionsClone);
  };

  const handleMultipleAnswer = (state: boolean) => {
    setMultipleAnswer(state);
    updateQuestions(state);
  };

  return (
    <QuestionUI
      selectedLanguage={selectedLanguage}
      questionNumber={questionNumber}
      questions={questions}
      multipleAnswer={multipleAnswer}
      handleMultipleAnswer={handleMultipleAnswer}
      setQuestions={setQuestions}
      questionTitle={questionTitle}
      setQuestionTitle={setQuestionTitle}
      updateQuestions={updateQuestions}
      answers={answers}
      removeQuestion={removeQuestion}
      removeAnswer={removeAnswer}
      addAnswer={addAnswer}
    />
  );
};

export default Question;

import React, { Dispatch, SetStateAction } from 'react';
import './style.css';
import { ArrayFormatter } from 'ui/utils/ArrayFormatter';
import { QuestionType } from 'ui/utils/SurveyDataEncoder/QuestionType';
import RightMenuUI from './ui';

interface RightMenuProps {
  languages: string[];
  setLanguages: Dispatch<SetStateAction<string[]>>;
  segmentation: string[];
  setSegmentation: Dispatch<SetStateAction<string[]>>;
  mandatory: boolean;
  setMandatory: Dispatch<SetStateAction<boolean>>;
  redirection: string;
  setRedirection: Dispatch<SetStateAction<string>>;
  questions: QuestionType[];
  setQuestions: Dispatch<SetStateAction<QuestionType[]>>
  onSaveSurvey: () => void;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  screen: string;
  setScreen: Dispatch<SetStateAction<string>>
}

const RightMenu: React.FC<RightMenuProps> = ({ languages, setLanguages, segmentation, setSegmentation, mandatory, setMandatory, redirection, setRedirection, questions, setQuestions, onSaveSurvey, active, setActive, isLoading, screen, setScreen }) => {
  const handleSegmentation = (country: string) => {
    setSegmentation(ArrayFormatter.removeOrAddElement(segmentation, country));
  };

  const handleLanguage = (language: string) => {
    setLanguages(ArrayFormatter.removeOrAddElement(languages, language));
  };

  const scrollFormContainerToBottom = () => {
    const element = document.getElementById('scroll-questions-content');
    element!.scrollTop = element!.scrollHeight - element!.clientHeight;
  };

  const addQuestion = () => {
    const questionsClone = [...questions];
    questionsClone.push(getQuestionEmptySchema());
    setQuestions(questionsClone);
    scrollFormContainerToBottom();
  };

  const getQuestionEmptySchema = (): QuestionType => {
    return {
      question: {
        en: '',
        es: '',
        po: ''
      },
      possible_answers: [],
      allows_multiple_answers: false
    };
  };

  const getScreenName = (): string => {
    switch (screen) {
      case 'dashboard' : return 'Dashboard screen';
      case 'field_weather' : return 'Field weather screen';
      case 'field_crop' : return 'Field crop screen';
      default: return 'Dashboard screen';
    }
  };

  return (
    <RightMenuUI
      languages={languages}
      handleLanguage={handleLanguage}
      segmentation={segmentation}
      handleSegmentation={handleSegmentation}
      mandatory={mandatory}
      setMandatory={setMandatory}
      redirection={redirection}
      setRedirection={setRedirection}
      addQuestion={addQuestion}
      onSaveSurvey={onSaveSurvey}
      active={active}
      setActive={setActive}
      isLoading={isLoading}
      screen={getScreenName}
      setScreen={setScreen}
    />
  );
};

export default RightMenu;

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpJsonError } from './HttpJsonError';
import { SessionStorage } from 'core/domain/SessionStorage';
import { NotAuthenticatedError } from './NotAuthenticatedError';
import { NetworkError } from './NetworkError';

export class HttpClient {
  private http: AxiosInstance;
  private readonly sessionStorage: SessionStorage;
  private baseUrl: string = 'https://api.boostertech.io:9443/BoosterAgro';

  constructor (session: SessionStorage) {
    this.sessionStorage = session;
    this.http = axios.create({
      baseURL: this.baseUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async get (url: string): Promise<any> {
    try {
      return await this.http.get(url, await this.configWithAuthHeader());
    } catch (e) {
      this.handleError(e);
    }
  }

  async post (url: string, jsonBody: Record<string, unknown>): Promise<any> {
    try {
      return await this.http.post(url, JSON.stringify(jsonBody), await this.configWithAuthHeader());
    } catch (e) {
      this.handleError(e);
    }
  }

  async requestAuthToken (url: string, jsonBody: Record<string, unknown>): Promise<any> {
    try {
      return await this.http.post(url, JSON.stringify(jsonBody), { auth: { username: 'admin.boosteragtech.com', password: 'BoosterAgTech443' } });
    } catch (e) {
      this.handleError(e);
    }
  }

  async put (url: string, jsonBody: Record<string, unknown> = {}): Promise<any> {
    try {
      return await this.http.put(url, JSON.stringify(jsonBody), await this.configWithAuthHeader());
    } catch (e) {
      this.handleError(e);
    }
  }

  async delete (url: string): Promise<any> {
    try {
      return await this.http.delete(url, await this.configWithAuthHeader());
    } catch (e) {
      this.handleError(e);
    }
  }

  async configWithAuthHeader (): Promise<AxiosRequestConfig> {
    if (!this.sessionStorage.hasSession()) { return {}; }
    return {
      headers: {
        'Authorization': 'Basic ' + this.sessionStorage.get()
      }
    };
  }

  handleError (e: any): void {
    if (e.response.status === 500) { throw new NetworkError(); }
    if (e.response.status === 401) { throw new NotAuthenticatedError(); }
    throw new HttpJsonError(e.response.status, e.response.data);
  }
}

import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'react-bootstrap';

interface LanguageSelectorProps {
  selectedLanguage: string;
  languages: string[];
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ selectedLanguage, languages, setSelectedLanguage }) => {
  return (
    <div className="mb-2">
      {languages.includes('english') && <Button variant={selectedLanguage === 'english' ? 'primary' : 'secondary'} size="sm" onClick={() => setSelectedLanguage('english')}>English</Button>}
      {languages.includes('spanish') && <Button variant={selectedLanguage === 'spanish' ? 'primary' : 'secondary'} size="sm" className="mx-1" onClick={() => setSelectedLanguage('spanish')}>Spanish</Button>}
      {languages.includes('portuguese') && <Button variant={selectedLanguage === 'portuguese' ? 'primary' : 'secondary'} size="sm" onClick={() => setSelectedLanguage('portuguese')}>Portuguese</Button>}
    </div>
  );
};

export default LanguageSelector;

import { InputTextType } from '../dashboard/components/Form/InputText/InputTextType';

export const titleData: InputTextType = {
  title: {
    en: 'Title',
    es: 'Título',
    po: 'Cabeceira'
  },
  placeholder: {
    en: 'Enter title',
    es: 'Ingrese el Título',
    po: 'Insira o cabeceira'
  }
};

export const questionData: InputTextType = {
  title: {
    en: 'Question',
    es: 'Pregunta',
    po: 'Pergunta'
  },
  placeholder: {
    en: 'Enter question',
    es: 'Ingrese la pregunta',
    po: 'Insira o pergunta'
  }
};

export const descriptionData: InputTextType = {
  title: {
    en: 'Description',
    es: 'Descripción',
    po: 'Descrição'
  },
  placeholder: {
    en: 'Enter description',
    es: 'Ingrese la descripción',
    po: 'Insira o descricao'
  }
};

export const answerData: InputTextType = {
  title: {
    en: 'Answer',
    es: 'Respuesta',
    po: 'Reposta'
  },
  placeholder: {
    en: 'Enter answer',
    es: 'Ingrese la respuesta',
    po: 'Insira o reposta'
  }
};

import { HttpClient } from './HttpClient';
import { SurveysService } from 'core/domain/SurveysService';
import { LoginIntent } from 'core/domain/LoginIntent';
import { Survey } from 'core/domain/Survey';
import { SurveyResult } from 'core/domain/SurveyResult';

export class SurveysHttpService implements SurveysService {
  private httpClient: HttpClient;

  constructor (httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async login (email: string, password: string): Promise<any> {
    const data = {
      email: email,
      password: password
    };
    const response = await this.httpClient.requestAuthToken('/clients/login', data);
    return new LoginIntent(response.data.status, response.data.token);
  }

  async getSurveys (screen: string): Promise<Survey[]> {
    const response = await this.httpClient.get('/surveys/admin');
    return response.data.surveys.map((survey: any) => this.surveyFromJson(survey));
  }

  private surveyFromJson (survey: any): Survey {
    return new Survey(survey.survey_id, survey.screen, survey.translations, survey.is_mandatory, survey.is_active, survey.created_at, survey.updated_at, survey.views, survey.answers, survey.segment.countries, survey.redirect_url);
  }

  async getSurveyResults (surveyId: string, language: string): Promise<SurveyResult> {
    const response = await this.httpClient.get(`/surveys/results?surveyId=${surveyId}&language=${language}`);
    return new SurveyResult(surveyId, response.data.survey_results.answers, response.data.survey_results.views, response.data.survey_results.details);
  }

  async saveSurveys (apiData: Record<string, any>): Promise<Record<string, any>> {
    return this.httpClient.post('/surveys', apiData);
  }

  async deleteSurveys (surveyId: string): Promise<Record<string, any>> {
    return this.httpClient.delete('/surveys?surveyId=' + surveyId);
  }
}

import { InputType } from './InputType';
import { QuestionType } from './QuestionType';
import { DataEncoder } from 'core/domain/DataEncoder';

export class SurveyDataEncoder implements DataEncoder {
  private _title: InputType = { en: '', es: '', po: '' };
  private _surveyId: string | null = null;
  private _screen: string = 'dashboard';
  private _description = { en: '', es: '', po: '' };
  private _active: boolean = false;
  private _questions: QuestionType[] = [{
    question: { en: '', es: '', po: '' },
    possible_answers: [{ en: '', es: '', po: '' }],
    allows_multiple_answers: false
  }];

  private _languages: string[] = [];
  private _redirection: string = '';
  private _mandatory: boolean = false;
  private _segmentation: string[] = [];
  set surveyId (value: string | null) { this._surveyId = value; }

  get surveyId (): string | null { return this._surveyId; }

  // eslint-disable-next-line accessor-pairs
  set title (value: InputType) { this._title = value; }

  // eslint-disable-next-line accessor-pairs
  set languages (value: string[]) { this._languages = value; }

  // eslint-disable-next-line accessor-pairs
  set redirection (value: string) { this._redirection = value; }

  // eslint-disable-next-line accessor-pairs
  set questions (value: QuestionType[]) { this._questions = value; }

  // eslint-disable-next-line accessor-pairs
  set description (value: { en: string; es: string; po: string }) { this._description = value; }

  // eslint-disable-next-line accessor-pairs
  set active (value: boolean) { this._active = value; }

  // eslint-disable-next-line accessor-pairs
  set mandatory (value: boolean) { this._mandatory = value; }

  // eslint-disable-next-line accessor-pairs
  set segmentation (value: string[]) { this._segmentation = value; }

  getAPIData (): Record<string, any> {
    return {
      survey_id: this._surveyId,
      screen: this._screen,
      translations: this.getTranslations(),
      redirect_url: this._redirection,
      is_mandatory: this._mandatory,
      is_active: this._active,
      segment: {
        countries: this.getCountries()
      }
    };
  }

  private getTranslations (): Record<string, any>[] {
    const translations = [];
    if (this._languages.includes('english')) {
      translations.push(SurveyDataEncoder.getTranslation('en', this._title.en, this._description.en, this.getQuestions('en')));
    }
    if (this._languages.includes('spanish')) {
      translations.push(SurveyDataEncoder.getTranslation('es', this._title.es, this._description.es, this.getQuestions('es')));
    }
    if (this._languages.includes('portuguese')) {
      translations.push(SurveyDataEncoder.getTranslation('po', this._title.po, this._description.po, this.getQuestions('po')));
    }
    return translations;
  }

  private static getTranslation (lang: string, title: string, description: string, questions: Record<string, any>) {
    return {
      language: lang === 'po' ? 'pt' : lang,
      title: title,
      description: description,
      questions: questions
    };
  }

  private getQuestions (lang: string): Record<string, any> {
    const questions: Record<string, any> = [];
    this._questions.forEach((question: Record<string, any>) => {
      const langQuestion = {
        question: question.question[lang],
        allows_multiple_answers: question.allows_multiple_answers,
        possible_answers: question.possible_answers.map((answer: Record<string, any>) => answer[lang])
      };
      questions.push(langQuestion);
    });
    return questions;
  }

  private getCountries () {
    const countries: string[] = [];
    if (this._segmentation.includes('argentina')) countries.push('ar');
    if (this._segmentation.includes('brasil')) countries.push('br');
    if (this._segmentation.includes('uruguay')) countries.push('ur');
    return countries;
  }

  get screen (): string {
    return this._screen;
  }

  // eslint-disable-next-line accessor-pairs
  set screen (screen: string) { this._screen = screen; }
}

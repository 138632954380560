import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { NotAuthenticatedError } from 'core/infrastructure/http/NotAuthenticatedError';
import ViewSurveyUI from './ui';
import { surveysService } from 'core/infrastructure/instances';
import GetSurveyResults from 'core/useCases/GetSurveyResults';
import { SurveyResult } from 'core/domain/SurveyResult';

const ViewSurvey: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const location = useLocation<Record<string, any>>();
  const params = useParams<Record<string, any>>();
  const [paramData, setParamData] = useState<Record<string, any>>({});
  const [surveyResults, setSurveyResults] = useState<SurveyResult>();

  useEffect(() => {
    setParamData(JSON.parse(location.state.data));
    getSurveys();
  }, []);

  const getSurveys = async () => {
    try {
      const getSurveyResults = new GetSurveyResults(surveysService);
      setSurveyResults(await getSurveyResults.execute(params.surveyId, 'es'));
    } catch (e) {
      handleErrors(e);
    }
    setIsLoading(false);
  };

  const handleErrors = (e: any) => {
    if (e instanceof NotAuthenticatedError) { history.push('/logout'); }
  };

  return (<ViewSurveyUI isLoading={isLoading} paramData={paramData} surveyResults={surveyResults!} />);
};

export default ViewSurvey;

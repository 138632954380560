import { DataDecoder } from 'core/domain/DataDecoder';
import { InputType } from './SurveyDataEncoder/InputType';
import { QuestionType } from './SurveyDataEncoder/QuestionType';
// import { QuestionType } from './SurveyDataEncoder/QuestionType';
// import { QuestionType } from './SurveyDataEncoder/QuestionType';

export class SurveyDataDecoder implements DataDecoder {
  private _survey: Record<string, any>;

  constructor (survey: Record<string, any>) {
    this._survey = survey;
  }

  getTitle (): InputType {
    return {
      es: this.getTitleByLanguage('es'),
      en: this.getTitleByLanguage('en'),
      po: this.getTitleByLanguage('pt')
    };
  }

  private getTitleByLanguage (language: string): string {
    const translation = this._survey._translations.filter((translation: Record<string, any>) => translation.language === language);
    if (translation[0]) return translation[0].title;
    return '';
  }

  getDescription (): InputType {
    return {
      es: this.getDescriptionByLanguage('es'),
      en: this.getDescriptionByLanguage('en'),
      po: this.getDescriptionByLanguage('pt')
    };
  }

  private getDescriptionByLanguage (language: string): string {
    const translation = this._survey._translations.filter((translation: Record<string, any>) => translation.language === language);
    if (translation[0]) return translation[0].description;
    return '';
  }

  getQuestions () {
    const questions: QuestionType[] = [];

    const countQuestions = this.getCountQuestions();

    for (let index = 0; index < countQuestions; index++) {
      const question: QuestionType = {
        question: this.getQuestionByIndex(index),
        possible_answers: this.getPossibleAnswersByIndex(index),
        allows_multiple_answers: this.getAllowMultipleAnswers(index)
      };
      questions.push(question);
    }
    return questions;
  }

  private getCountQuestions () {
    if (!this._survey._translations[0].questions) { return 0; }
    return this._survey._translations[0].questions.length;
  }

  private getQuestionByIndex (index: number): InputType {
    const question: InputType = { en: '', es: '', po: '' };
    this._survey._translations.forEach((translation: Record<string, any>) => {
      const language: string = translation.language === 'pt' ? 'po' : translation.language;
      // @ts-ignore
      question[language] = translation.questions[index].question;
    });
    return question;
  }

  private getPossibleAnswersByIndex (index: number): InputType[] {
    const possibleAnswers: InputType[] = [];
    this._survey._translations.forEach((translation: Record<string, any>) => {
      const language: string = translation.language === 'pt' ? 'po' : translation.language;
      translation.questions[index].possible_answers.forEach((pa: string, paIndex: number) => {
        possibleAnswers[paIndex] = this.initPossibleAnwerIfNot(possibleAnswers, paIndex);
        // @ts-ignore
        possibleAnswers[paIndex][language] = pa;
      });
    });

    return possibleAnswers;
  }

  private initPossibleAnwerIfNot (possibleAnswers: InputType[], paIndex: number): InputType {
    if (!possibleAnswers[paIndex]) { return { es: '', en: '', po: '' }; }
    return possibleAnswers[paIndex];
  }

  private getAllowMultipleAnswers (index: number) {
    return this._survey._translations[0].questions[index].allows_multiple_answers;
  }

  getSegmentation (): string[] {
    const segment = this._survey._segmentation.map((country: string) => {
      if (country == 'ar') { return 'argentina'; }
      if (country == 'br') { return 'brasil'; }
      if (country == 'ur') { return 'uruguay'; }
    });
    return segment;
  }

  getLanguages (): string[] {
    const languages: string[] = [];
    this._survey._translations.forEach((translation: Record<string, any>) => {
      if (translation.language === 'es') { languages.push('spanish'); }
      if (translation.language === 'en') { languages.push('english'); }
      if (translation.language === 'pt') { languages.push('portuguese'); }
    });
    return languages;
  }

  getMandatory (): boolean {
    return this._survey._mandatory;
  }

  getRedirection (): string {
    return this._survey._redirectUrl;
  }

  getActive (): boolean {
    return this._survey._isActive;
  }

  getScreen (): string {
    return this._survey._screen;
  }
}

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { answerData } from 'ui/language/InputText';
import { Button, Col, Row } from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import { InputType } from 'ui/utils/SurveyDataEncoder/InputType';
import InputText from '../../../InputText';
import { QuestionType } from 'ui/utils/SurveyDataEncoder/QuestionType';

interface QuestionProps {
  selectedLanguage: string;
  number: number;
  removeAnswer: (index: number) => void;
  answer: InputType;
  questions: QuestionType[];
  setQuestions: Dispatch<SetStateAction<QuestionType[]>>
  questionNumber: number;
}

const Answer: React.FC<QuestionProps> = ({ selectedLanguage, number, removeAnswer, answer, questions, setQuestions, questionNumber }) => {
  const [answerTitle, setAnswerTitle] = useState<InputType>(answer);

  useEffect(() => setAnswerTitle(answer), []);

  const updateAnswer = () => {
    let newAnswer: InputType = {
      en: answerTitle.en,
      es: answerTitle.es,
      po: answerTitle.po
    };
    const questionsCopy = [...questions];
    // @ts-ignore
    questionsCopy[questionNumber - 1].possible_answers[number - 1] = newAnswer;
    setQuestions(questionsCopy);
  };

  return (
    <Row key={number}>
      <Col xs={4} className="d-flex align-items-center justify-content-end">
        <strong>R{number}.Q1</strong>
      </Col>
      <Col xs={7}>
        <InputText selectedLanguage={selectedLanguage} dataType={answerData} label={answerTitle} setLabel={setAnswerTitle} postUpdate={updateAnswer} />
      </Col>
      <Col xs={1} className="p-0">
        <Button variant="danger" className="btn-sm mt-2" onClick={() => removeAnswer(number - 1)}><Trash /></Button>
      </Col>
    </Row>
  );
};

export default Answer;

import React from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import './style.css';

interface LoginUIProps {
  isLoading: boolean;
  tryLogin: () => void;
  email: string;
  emailError: string;
  password: string;
  passwordError: string;
  handleUsername: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  invalidCredentialsError: string;
}

const LoginUI: React.FC<LoginUIProps> = ({ isLoading, tryLogin, email, password, emailError, passwordError, handleUsername, handlePassword, invalidCredentialsError }) => {
  return (
    <Container>
      <Row>
        <Col className="text-center mt-5">
          <h1>Surveys ADMIN</h1>
        </Col>
      </Row>
      <Row>
        <Col className="mt-5" md={{ span: 4, offset: 4 }}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                value={email}
                onChange={handleUsername}
                isInvalid={emailError !== ''} />
              <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={handlePassword}
                isInvalid={passwordError !== ''}
              />
              <Form.Control.Feedback type="invalid">{passwordError}</Form.Control.Feedback>
            </Form.Group>
            <div className="invalid-feedback" style={{ display: 'block' }}>{invalidCredentialsError}</div>
            <Button className="mt-2" variant="primary" disabled={isLoading} onClick={tryLogin}>{isLoading ? 'Loading…' : 'Login'}</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginUI;

import React from 'react';
import './style.css';
import { Survey } from 'core/domain/Survey';
import { ViewSurveyVm } from '../../ViewSurveyVm';
import SurveyListCardUI from './ui';
import DeleteSurvey from 'core/useCases/DeleteSurvey';
import { surveysService } from 'core/infrastructure/instances';
import { DateFormatter } from 'ui/utils/DateFormatter';

interface SurveyListCardProps {
  survey: Survey;
  language: string;
  removeSurveyList: (surveyId: string) => void;
}

const SurveyListCard: React.FC<SurveyListCardProps> = ({ survey, language, removeSurveyList }) => {
  const data = survey.getSurveyData(language);
  const getViewSurveyVM = (): ViewSurveyVm => {
    return {
      audience: survey.views,
      completed: survey.answers,
      date: DateFormatter.timestampToDateSpanish(data.updatedAt),
      mandatory: survey.mandatory ? 'Yes' : 'No',
      redirection: survey.redirect,
      languages: survey.getLanguages(),
      segmentation: survey.getSegmentation(),
      status: survey.isActive ? 'Active' : 'Inactive',
      title: survey.getTitle(language)
    };
  };

  const tryDeleteSurvey = (surveyId: string) => {
    if (confirm('Are you sure to delete this survey?')) { deleteSurvey(surveyId); }
  };

  const deleteSurvey = async (surveyId: string) => {
    try {
      const deleteSurvey = new DeleteSurvey(surveysService);
      await deleteSurvey.execute(surveyId);
      removeSurveyList(surveyId);
    } catch (e) {
      console.log(e);
    }
  };

  return (<SurveyListCardUI survey={survey} viewSurveyVM={getViewSurveyVM()} data={data} deleteSurvey={tryDeleteSurvey} />);
};

export default SurveyListCard;

import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from 'ui/components/Loading';
import { SurveyResult } from 'core/domain/SurveyResult';
import Result from './components/SurveyResult';
import ViewHeader from './components/ViewHeader';
import './style.css';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';

interface ViewSurveyUIProps {
  isLoading: boolean;
  paramData: Record<string, any>;
  surveyResults: SurveyResult;
}

const ViewSurveyUI: React.FC<ViewSurveyUIProps> = ({ isLoading, paramData, surveyResults }) => {
  return (
    <>
      <h2><Link to={'/'} className="link"><ArrowLeft /></Link> View Survey</h2>
      {isLoading && <Loading />}
      {!isLoading && <Container>
        <ViewHeader
          title={paramData.title}
          date={paramData.date}
          status={paramData.status}
          audience={paramData.audience}
          completed={paramData.completed} />
        <Row className="body-container">
          <Col xs={4}>
            <strong>Segmentación:</strong> {paramData.segmentation} <br />
            <strong>Language:</strong> {paramData.languages}<br />
            <strong>Mandatory:</strong> {paramData.mandatory}<br />
            <strong>Redirection:</strong> {paramData.redirection}<br />
          </Col>
          <Col xs={8} className="answers-container">
            {surveyResults && surveyResults.details.map((result: Record<string, any>, index: number) => <Result key={index} questionNumber={index + 1} result={result} />) }
          </Col>
        </Row>
      </Container>}
    </>
  );
};

export default ViewSurveyUI;

import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { Button, Col, DropdownButton, Form, Row, Dropdown } from 'react-bootstrap';
import './style.css';

interface RightMenuProps {
  languages: string[];
  handleLanguage: (language: string) => void;
  segmentation: string[];
  handleSegmentation: (country: string) => void;
  mandatory: boolean;
  setMandatory: Dispatch<SetStateAction<boolean>>;
  redirection: string;
  setRedirection: Dispatch<SetStateAction<string>>;
  addQuestion: () => void;
  onSaveSurvey: () => void;
  active: boolean;
  setActive: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  screen: () => string;
  setScreen: (screen: any) => void;
}

const RightMenuUI: React.FC<RightMenuProps> = ({ languages, handleLanguage, segmentation, handleSegmentation, mandatory, setMandatory, redirection, setRedirection, addQuestion, onSaveSurvey, active, setActive, isLoading, screen, setScreen }) => {
  return (
    <Form className="survey-form">
      <Row>
        <Col>
          <Form.Group as={Col}>
            <Form.Check className="form-control-lg" type="switch" label="Active Survey" checked={active} onChange={() => setActive(!active)} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-grid gap-2 mt-4">
            <Button variant="primary" onClick={addQuestion}>Add Question</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group as={Col}>
            <Form.Label>Segmentation</Form.Label>
            <Form.Check
              type="switch"
              label="Argentina"
              checked={segmentation.includes('argentina')}
              onChange={() => handleSegmentation('argentina')}
            />
            <Form.Check
              type="switch"
              label="Brasil"
              checked={segmentation.includes('brasil')}
              onChange={() => handleSegmentation('brasil')}
            />
            <Form.Check
              type="switch"
              label="Uruguay"
              checked={segmentation.includes('uruguay')}
              onChange={() => handleSegmentation('uruguay')}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Col}>
            <Form.Label>Languages</Form.Label>
            <Form.Check type="switch" label="English" checked={languages.includes('english')} onChange={() => handleLanguage('english')} />
            <Form.Check type="switch" label="Spanish" checked={languages.includes('spanish')} onChange={() => handleLanguage('spanish')} />
            <Form.Check type="switch" label="Portuguese" checked={languages.includes('portuguese')} onChange={() => handleLanguage('portuguese')} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <Form.Group as={Col}>
            <Form.Check type="radio" label="Mandatory" name="radioMandatory" checked={mandatory} onChange={() => setMandatory(true)} />
            <Form.Check type="radio" label="Not mandatory" name="radioMandatory" checked={!mandatory} onChange={() => setMandatory(false)} />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Col>
            <DropdownButton title={screen()} className={'mt-3 select-dropdown-screen'} onSelect={setScreen}>
              <Dropdown.Item eventKey="dashboard">Dashboard screen</Dropdown.Item>
              <Dropdown.Item eventKey="field_weather">Field weather screen</Dropdown.Item>
              <Dropdown.Item eventKey="field_crops">Field crops screen</Dropdown.Item>
            </DropdownButton>
          </Col>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>Redirection</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter url here"
            value={redirection}
            onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setRedirection(e.target.value)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-grid gap-2 mt-4">
            <Button variant="secondary" disabled={isLoading} onClick={onSaveSurvey}>{isLoading ? 'Saving survey…' : 'Save survey'}</Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default RightMenuUI;

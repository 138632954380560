import { SurveysService } from 'core/domain/SurveysService';
import { Validator } from 'core/domain/validation/Validator';
import { SessionStorage } from 'core/domain/SessionStorage';
import { EmptyFieldError } from 'core/domain/errors/EmptyFieldError';
import { EmailInvalidFormatError } from 'core/domain/errors/EmailInvalidFormatError';
import { InvalidCredentialsError } from '../domain/errors/InvalidCredentialsError';

export default class Login {
  private service: SurveysService;
  private sessionStorage: SessionStorage;

  constructor (service: SurveysService, sessionStorage: SessionStorage) {
    this.service = service;
    this.sessionStorage = sessionStorage;
  }

  async execute (email: string, password: string): Promise<string> {
    this.validate(email, password);
    return this.tryLogin(email, password);
  }

  private validate = (email: string, password: string) => {
    const validator = new Validator();
    validator.empty(email, new EmptyFieldError('email'));
    validator.empty(password, new EmptyFieldError('password'));
    validator.emailFormat(email, new EmailInvalidFormatError());
  };

  private async tryLogin (username: string, password: string): Promise<string> {
    const loginIntent = await this.service.login(username, password);
    if (loginIntent.status === 401) { throw new InvalidCredentialsError(); }
    if (loginIntent.status === 200) {
      await this.sessionStorage.store(loginIntent.token);
      return loginIntent.token;
    }
    throw Error;
  }
}

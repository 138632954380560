import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import LoginUI from './ui';
import LoginUC from 'core/useCases/Login';
import { DomainError } from 'core/domain/errors/DomainError';
import { EmptyFieldError } from 'core/domain/errors/EmptyFieldError';
import { EmailInvalidFormatError } from 'core/domain/errors/EmailInvalidFormatError';
import { InvalidCredentialsError } from 'core/domain/errors/InvalidCredentialsError';
import { surveysService, sessionStorage } from 'core/infrastructure/instances';

interface LoginProps {
  setToken: Dispatch<SetStateAction<string>>;
}

const Login: React.FC<LoginProps> = ({ setToken }) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [invalidCredentialsError, setInvalidCredentialsError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const token = sessionStorage.get();
    if (token) { setToken(token); }
  }, [setToken]);

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => { setEmail(event.target.value); };

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value); };

  const tryLogin = async () => {
    setIsLoading(true);
    cleanAllErrors();
    try { await login(); } catch (e) { handleErrors(e); }
    setIsLoading(false);
  };

  const cleanAllErrors = () => {
    setEmailError('');
    setPasswordError('');
    setInvalidCredentialsError('');
  };

  const login = async () => {
    const login = new LoginUC(surveysService, sessionStorage);
    const token = await login.execute(email, password);
    setToken(token);
  };

  const handleErrors = (e: DomainError) => {
    if (e instanceof EmptyFieldError) { handleEmptyFieldError(e); }
    if (e instanceof EmailInvalidFormatError) { setEmailError(e.message); }
    if (e instanceof InvalidCredentialsError) { setInvalidCredentialsError(e.message); }
  };

  const handleEmptyFieldError = (e: EmptyFieldError) => {
    switch (e.fieldName) {
      case 'email': setEmailError(e.message); break;
      default: setPasswordError(e.message);
    }
  };

  return (
    <LoginUI
      tryLogin={tryLogin}
      isLoading={isLoading}
      email={email}
      emailError={emailError}
      password={password}
      passwordError={passwordError}
      handleUsername={handleEmail}
      handlePassword={handlePassword}
      invalidCredentialsError={invalidCredentialsError}
    />
  );
};

export default Login;

export class Survey {
  private readonly _surveyId: string = '12345';
  private readonly _screen: string = 'none';
  private readonly _translations: Record<string, any>[] = [];
  private readonly _mandatory: boolean = false;
  private readonly _isActive: boolean = false;
  private readonly _createdAt: string = '';
  private readonly _updatedAt: string = '1234';
  private readonly _views: number = 0;
  private readonly _answers: number = 0;
  private readonly _segmentation: string[] = [];
  private readonly _redirectUrl: string = '';

  constructor (surveyId: string, screen: string, translations: Record<string, any>[], mandatory: boolean, isActive: boolean, createdAt: string, updatedAt: string, views: number, answers: number, segmentation: string[], redirectUrl: string) {
    this._surveyId = surveyId;
    this._screen = screen;
    this._translations = translations;
    this._mandatory = mandatory;
    this._isActive = isActive;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
    this._views = views;
    this._answers = answers;
    this._segmentation = segmentation;
    this._redirectUrl = redirectUrl;
  }

  get surveyId (): string {
    return this._surveyId;
  }

  get screen (): string {
    return this._screen;
  }

  get translations (): Record<string, any> {
    return this._translations;
  }

  get redirect (): string {
    return this._redirectUrl;
  }

  getLanguages (): string {
    let response = '';
    if (this.getTranslation('en')) { response += 'EN, '; }
    if (this.getTranslation('es')) { response += 'ES, '; }
    if (this.getTranslation('po')) { response += 'PO, '; }
    return response.slice(0, -2);
  }

  getSegmentation (): string {
    let response = '';
    this._segmentation.forEach((segment: string) => {
      if (segment === 'ar') { response += 'Argentina, '; }
      if (segment === 'br') { response += 'Brasil, '; }
      if (segment === 'ur') { response += 'Uruguay, '; }
    });
    return response.slice(0, -2);
  }

  getSurveyData (language: string): Record<string, any> {
    const surveys = this.translations.filter((translation: any) => translation.language === language);
    return surveys[0];
  }

  get mandatory (): boolean {
    return this._mandatory;
  }

  get views (): number {
    return this._views;
  }
  get answers (): number {
    return this._answers;
  }

  get isActive (): boolean {
    return this._isActive;
  }

  getDate () {
    return (this._updatedAt === '') ? this._createdAt : this._updatedAt;
  }

  getTitle (lang: string) {
    const translation = this.getTranslation(lang);
    return translation.title;
  }

  private getTranslation (lang: string): Record<string, any> {
    const translation = this.translations.filter((translation: any) => translation.language === lang);
    return translation[0];
  }
}

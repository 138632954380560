import { SurveysService } from 'core/domain/SurveysService';
import { DataEncoder } from '../domain/DataEncoder';
import { SaveSurveyError } from '../infrastructure/http/SaveSurveyError';

export default class SaveSurveys {
  private service: SurveysService;
  private surveyEncoder: DataEncoder;

  constructor (service: SurveysService, surveyEnconder: DataEncoder) {
    this.service = service;
    this.surveyEncoder = surveyEnconder;
  }

  async execute (): Promise<void> {
    const response: Record<string, any> = await this.service.saveSurveys(this.surveyEncoder.getAPIData());
    if (!response) { throw new SaveSurveyError(); }
    if (!response.data) { throw new SaveSurveyError(); }
    if (response.data.status !== 200) { throw new SaveSurveyError(); }
  }
}

import React, { useEffect, useState } from 'react';
import CESurveyUI from './ui';
import { SurveyDataEncoder } from 'ui/utils/SurveyDataEncoder/SurveyDataEncoder';
import { QuestionType } from 'ui/utils/SurveyDataEncoder/QuestionType';
import { InputType } from 'ui/utils/SurveyDataEncoder/InputType';
import { surveysService } from 'core/infrastructure/instances';
import SaveSurveys from 'core/useCases/SaveSurveys';
import { useHistory, useLocation, useParams } from 'react-router';
import { SurveyDataDecoder } from '../../utils/SurveyDataDecoder';
import './style.css';

const CESurvey: React.FC = () => {
  const history = useHistory();
  const params = useParams<Record<string, any>>();
  const [languages, setLanguages] = useState<string[]>(['english']);
  const [screen, setScreen] = useState<string>('dashboard');
  const [selectedLanguage, setSelectedLanguage] = useState<string>('english');
  const [segmentation, setSegmentation] = useState<string[]>(['argentina']);
  const [mandatory, setMandatory] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);
  const [redirection, setRedirection] = useState<string>('');
  const [title, setTitle] = useState<InputType>({ en: '', es: '', po: '' });
  const [description, setDescription] = useState<InputType>({ en: '', es: '', po: '' });
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [surveySaved, setSurveySaved] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const location = useLocation<Record<string, any>>();
  const surveyEncoder = new SurveyDataEncoder();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (params.surveyId) {
      setEditMode(true);
      setData();
    }
  }, []);

  const setData = async () => {
    const survey = JSON.parse(location.state.survey);
    const surveyDecoder = new SurveyDataDecoder(survey);
    setTitle(surveyDecoder.getTitle());
    setDescription(surveyDecoder.getDescription());
    setSegmentation(surveyDecoder.getSegmentation());
    setMandatory(surveyDecoder.getMandatory());
    setRedirection(surveyDecoder.getRedirection());
    setActive(surveyDecoder.getActive());
    setLanguages(surveyDecoder.getLanguages());
    setQuestions(surveyDecoder.getQuestions());
    setScreen(surveyDecoder.getScreen());
  };

  const saveSurvey = async () => {
    setIsLoading(true);
    setEncoderData();
    const saveSurvey = new SaveSurveys(surveysService, surveyEncoder);
    surveyEncoder.surveyId = params.surveyId ? params.surveyId : null;
    try {
      await saveSurvey.execute();
      setSurveySaved(true);
      setTimeout(() => { history.push('/'); }, 2000);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const setEncoderData = () => {
    surveyEncoder.title = title;
    surveyEncoder.languages = languages;
    surveyEncoder.redirection = redirection;
    surveyEncoder.description = description;
    surveyEncoder.questions = questions;
    surveyEncoder.active = active;
    surveyEncoder.mandatory = mandatory;
    surveyEncoder.segmentation = segmentation;
    surveyEncoder.screen = screen;
  };

  return (
    <CESurveyUI
      languages={languages}
      setLanguages={setLanguages}
      selectedLanguage={selectedLanguage}
      setSelectedLanguage={setSelectedLanguage}
      segmentation={segmentation}
      setSegmentation={setSegmentation}
      mandatory={mandatory}
      setMandatory={setMandatory}
      active={active}
      setActive={setActive}
      redirection={redirection}
      setRedirection={setRedirection}
      questions={questions}
      setQuestions={setQuestions}
      title={title}
      setTitle={setTitle}
      description={description}
      setDescription={setDescription}
      onSaveSurvey={saveSurvey}
      surveySaved={surveySaved}
      editMode={editMode}
      isLoading={isLoading}
      screen={screen}
      setScreen={setScreen}
    />
  );
};

export default CESurvey;

export class SurveyResult {
  private readonly _views: number;
  private readonly _surveyId: string;
  private readonly _answers: number;
  private readonly _details: Record<string, any>;

  constructor (surveyId: string, answers: number, views: number, details: Record<string, any>) {
    this._surveyId = surveyId;
    this._answers = answers;
    this._views = views;
    this._details = details;
  }

  get surveyId (): string {
    return this._surveyId;
  }

  get answers (): number {
    return this._answers;
  }

  get views (): number {
    return this._views;
  }

  get details (): Record<string, any> {
    return this._details;
  }
}

import { DomainError } from 'core/domain/errors/DomainError';

export class Validator {
  empty (field: string, error: DomainError): void {
    if (field === '') { throw error; }
  }

  emailFormat (field: string, error: DomainError): void {
    // eslint-disable-next-line no-useless-escape
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = emailRegex.test(field);
    if (!isValid) { throw error; }
  }
}

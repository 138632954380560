import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';

interface ViewHeaderProps {
  title: string;
  date: string;
  status: string;
  audience: number;
  completed: number;
}

const ViewHeader: React.FC<ViewHeaderProps> = ({ title, date, status, audience, completed }) => {
  return (
    <>
      <Row className="header">
        <Col>Title</Col>
        <Col>Date</Col>
        <Col>Status</Col>
        <Col>Audience</Col>
        <Col>Completed</Col>
      </Row>
      <Row className="body">
        <Col>{title}</Col>
        <Col>{date}</Col>
        <Col>{status}</Col>
        <Col>{audience}</Col>
        <Col>{completed}</Col>
      </Row>
    </>
  );
};

export default ViewHeader;

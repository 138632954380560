import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ResultItem from './components/ResultItem';
import './style.css';

interface ResultProps {
  result: Record<string, any>;
  questionNumber: number;
}

const Result: React.FC<ResultProps> = ({ result, questionNumber }) => {
  return (
    <div>
      <Row>
        <Col><strong>Q{questionNumber}:</strong> {result.question}</Col>
      </Row>
      <Row className="answers-container">
        <Col>
          {result.answers.map((answer: Record<string, any>, index: number) => <ResultItem key={index} questionNumber={questionNumber} answerNumber={index + 1} answer={answer} />)}
        </Col>
      </Row>
    </div>
  );
};

export default Result;

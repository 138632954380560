import React, { Dispatch, SetStateAction, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import { InputTextType } from './InputTextType';
import { InputType } from 'ui/utils/SurveyDataEncoder/InputType';

interface InputTextProps {
  selectedLanguage: string;
  dataType: InputTextType;
  showTitle?: boolean;
  titleNumber?: number;
  label: InputType;
  setLabel: Dispatch<SetStateAction<InputType>>;
  postUpdate?: () => void
}

const InputText: React.FC<InputTextProps> = ({ selectedLanguage, dataType, showTitle, titleNumber, label, setLabel, postUpdate }) => {
  useEffect(() => { if (postUpdate) { postUpdate(); } }, [label]);

  const handleLabel = (event: React.ChangeEvent<HTMLInputElement>, lang: string) => {
    setLabel(prevState => ({
      ...prevState,
      [lang]: event.target.value
    }));
  };

  return (
    <div className="mt-2">
      {(selectedLanguage === 'english') && <Form.Group className="mb-3" controlId="titleEN">
        {showTitle && <Form.Label><strong>{dataType.title.en} {titleNumber}</strong></Form.Label>}
        <Form.Control
          type="text"
          placeholder={dataType.placeholder.en}
          value={label.en}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleLabel(event, 'en')}
        />
      </Form.Group>}
      {(selectedLanguage === 'spanish') && <Form.Group className="mb-3" controlId="titleES">
        {showTitle && <Form.Label><strong>{dataType.title.es} {titleNumber}</strong></Form.Label>}
        <Form.Control
          type="text"
          placeholder={dataType.placeholder.es}
          value={label.es}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleLabel(event, 'es')}
        />
      </Form.Group>}
      {(selectedLanguage === 'portuguese') && <Form.Group className="mb-3" controlId="titlePO">
        {showTitle && <Form.Label><strong>{dataType.title.po} {titleNumber}</strong></Form.Label>}
        <Form.Control
          type="text"
          placeholder={dataType.placeholder.po}
          value={label.po}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleLabel(event, 'po')}
        />
      </Form.Group>}
    </div>
  );
};

export default InputText;

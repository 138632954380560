import React from 'react';
import { Survey } from 'core/domain/Survey';
import Loading from 'ui/components/Loading';
import SurveyListCard from './components/SurveyListCard';
import { Link } from 'react-router-dom';

import './style.css';

interface SurveyListUIProps {
  surveys: Survey[];
  isLoading: boolean;
  language: string;
  removeSurveyList: (surveyId: string) => void;
}
const SurveyListUI: React.FC<SurveyListUIProps> = ({ surveys, isLoading, language, removeSurveyList }) => {
  return (
    <>
      <h2>Surveys Admin</h2>
      <div className="d-flex justify-content-end">
        <Link to="/create-survey" className="btn btn-success text-light">Create survey</Link>
      </div>
      {isLoading && <Loading />}
      {surveys.map((survey: Survey) => <SurveyListCard key={survey.surveyId} survey={survey} language={language} removeSurveyList={removeSurveyList} />)}
    </>
  );
};

export default SurveyListUI;

import React, { Dispatch, SetStateAction } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import { InputTextType } from '../InputText/InputTextType';
import { InputType } from '../../../../utils/SurveyDataEncoder/InputType';

interface DescriptionProps {
  selectedLanguage: string;
  data: InputTextType;
  description: InputType;
  setDescription: Dispatch<SetStateAction<InputType>>;
}

const Description: React.FC<DescriptionProps> = ({ selectedLanguage, data, description, setDescription }) => {
  const handleDescription = (event: React.ChangeEvent<HTMLInputElement>, lang: string) => {
    setDescription(prevState => ({
      ...prevState,
      [lang]: event.target.value
    }));
  };

  return (
    <div className="mt-2">
      {(selectedLanguage === 'english') && <Form.Group className="mb-3">
        <Form.Label><strong>{data.title.en}</strong></Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description.en}
          placeholder={data.placeholder.en}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDescription(event, 'en')}
        />
      </Form.Group>}
      {(selectedLanguage === 'spanish') && <Form.Group className="mb-3">
        <Form.Label><strong>{data.title.es}</strong></Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description.es}
          placeholder={data.placeholder.es}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDescription(event, 'es')}
        />
      </Form.Group>}
      {(selectedLanguage === 'portuguese') && <Form.Group className="mb-3">
        <Form.Label><strong>{data.title.po}</strong></Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={description.po}
          placeholder={data.placeholder.po}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleDescription(event, 'po')}
        />
      </Form.Group>}
    </div>
  );
};

export default Description;

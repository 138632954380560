import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DateFormatter } from 'ui/utils/DateFormatter';
import { Survey } from 'core/domain/Survey';
import { Eye, Pencil, Trash } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { ViewSurveyVm } from '../../ViewSurveyVm';
import './style.css';

interface SurveyListCardUIProps {
  survey: Survey;
  viewSurveyVM: ViewSurveyVm;
  data: Record<string, any>;
  deleteSurvey: (surveyId: string) => void;
}

const SurveyListCardUI: React.FC<SurveyListCardUIProps> = ({ survey, viewSurveyVM, data, deleteSurvey }) => {
  return (
    <Container className="mt-2">
      <Row className="header">
        <Col>Title</Col>
        <Col>Date</Col>
        <Col>Status</Col>
        <Col>Audience</Col>
        <Col>Completed</Col>
        <Col>Action</Col>
      </Row>
      <Row className="body">
        <Col>{ data ? data.title : '' }</Col>
        <Col>{ data ? DateFormatter.timestampToDateSpanish(data.updatedAt) : ''}</Col>
        <Col>{ survey.isActive ? 'Active' : 'Inactive' }</Col>
        <Col>{ survey.views }</Col>
        <Col>{ survey.answers }</Col>
        <Col>
          <Link to={{ pathname: `/view-survey/${survey.surveyId}`, state: { data: JSON.stringify(viewSurveyVM) } }}><Eye className="ml-4 icon" /></Link>
          <Link to={{ pathname: `/edit-survey/${survey.surveyId}`, state: { survey: JSON.stringify(survey) } }}><Pencil className="ml-4 icon" /></Link>
          <a onClick={() => deleteSurvey(survey.surveyId)}><Trash className="ml-4 icon" /></a>
        </Col>
      </Row>
    </Container>
  );
};

export default SurveyListCardUI;

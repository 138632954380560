import { SessionStorage } from 'core/domain/SessionStorage';

const SESSION_KEY = 'surveys_auth_token';

export class LocalSessionStorage implements SessionStorage {
  get (): string | null {
    return localStorage.getItem(SESSION_KEY);
  }

  store (key: string): void {
    localStorage.setItem(SESSION_KEY, key);
  }

  clear (): void {
    localStorage.removeItem(SESSION_KEY);
  }

  hasSession (): boolean {
    return localStorage.getItem(SESSION_KEY) !== null;
  }
}

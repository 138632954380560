import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './style.css';

interface ResultProps {
  questionNumber: number;
  answerNumber: number;
  answer: Record<string, any>
}

const ResultItem: React.FC<ResultProps> = ({ questionNumber, answerNumber, answer }) => {
  return (
    <Row>
      <Col><strong>R{answerNumber}.Q{questionNumber}:</strong> {answer.answer}</Col>
      <Col className="text-center"><span className="fst-italic fw-bold mx-2">{answer.count}</span> ({answer.percent}%)</Col>
    </Row>
  );
};

export default ResultItem;

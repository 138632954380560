import React, { useState } from 'react';
import Login from 'ui/auth/Login';
import Dashboard from 'ui/dashboard';
import './style.css';

const App: React.FC = () => {
  const [token, setToken] = useState<string>('');

  return (<>{token ? <Dashboard /> : <Login setToken={setToken} />}</>);
};

export default App;

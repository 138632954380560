import React, { useEffect, useState } from 'react';
import { surveysService } from 'core/infrastructure/instances';
import GetSurveys from 'core/useCases/GetSurveys';
import { useHistory } from 'react-router';
import { NotAuthenticatedError } from 'core/infrastructure/http/NotAuthenticatedError';
import { Survey } from 'core/domain/Survey';
import SurveyListUI from './ui';
import './style.css';

const SurveyList: React.FC = () => {
  const history = useHistory();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => { getSurveys(); }, []);

  const getSurveys = async () => {
    try {
      const getSurveys = new GetSurveys(surveysService);
      setSurveys(await getSurveys.execute('dashboard'));
    } catch (e) {
      handleErrors(e);
    }
    setIsLoading(false);
  };

  const handleErrors = (e: any) => {
    if (e instanceof NotAuthenticatedError) { history.push('/logout'); }
  };

  const removeSurveyList = (surveyId: string) => {
    let surveysClone = [...surveys];
    surveysClone = surveysClone.filter((survey: Record<string, any>) => survey._surveyId !== surveyId);
    setSurveys(surveysClone);
  };

  return (<SurveyListUI surveys={surveys} isLoading={isLoading} language='es' removeSurveyList={removeSurveyList} />);
};

export default SurveyList;

import React, { Dispatch, SetStateAction } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import InputText from '../InputText';
import { questionData } from 'ui/language/InputText';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Answer from './components/Answer';
import { QuestionType } from 'ui/utils/SurveyDataEncoder/QuestionType';
import { InputType } from 'ui/utils/SurveyDataEncoder/InputType';
import { Trash } from 'react-bootstrap-icons';
import './style.css';

interface QuestionPropsUI {
  selectedLanguage: string;
  questionNumber: number;
  questions: QuestionType[];
  setQuestions: Dispatch<SetStateAction<QuestionType[]>>;
  questionTitle: InputType;
  setQuestionTitle: Dispatch<SetStateAction<InputType>>;
  updateQuestions: (state: boolean) => void;
  answers: InputType[];
  removeQuestion: (index: number) => void;
  removeAnswer: (index: number) => void;
  addAnswer: () => void;
  multipleAnswer: boolean;
  handleMultipleAnswer: (state: boolean) => void;
}

const QuestionUI: React.FC<QuestionPropsUI> = ({ selectedLanguage, questionNumber, questions, setQuestions, questionTitle, setQuestionTitle, updateQuestions, answers, removeQuestion, removeAnswer, addAnswer, multipleAnswer, handleMultipleAnswer }) => {
  return (
    <div className="mt-2">
      <Row>
        <Col xs={11}>
          <InputText
            selectedLanguage={selectedLanguage}
            dataType={questionData}
            showTitle
            titleNumber={questionNumber}
            label={questionTitle}
            setLabel={setQuestionTitle}
            postUpdate={() => updateQuestions(multipleAnswer)}
          />
        </Col>
        <Col xs={1} className="p-0">
          <Button variant="danger" className="btn-sm remove-question-button" onClick={() => removeQuestion(questionNumber - 1)}><Trash /></Button>
        </Col>
      </Row>
      <Row>
        <Col className={'d-flex flex-row'}>
          <Form.Check type="radio" label="Multiple answers" name={'radioMultiple-' + questionNumber} checked={multipleAnswer} onChange={() => { handleMultipleAnswer(true); }} />
          <Form.Check style={{ marginLeft: 10 }} type="radio" label="Single answer" name={'radioMultiple-' + questionNumber} checked={!multipleAnswer} onChange={() => { handleMultipleAnswer(false); }} />
        </Col>
      </Row>
      {answers.map((answer: InputType, index: number) =>
        <Answer
          key={index}
          selectedLanguage={selectedLanguage}
          number={index + 1}
          removeAnswer={removeAnswer}
          answer={answer}
          questions={questions}
          setQuestions={setQuestions}
          questionNumber={questionNumber}
        />)}
      <Row>
        <Col xs={{ span: 2, offset: 10 }}>
          <Button variant="primary" className="btn-sm" onClick={addAnswer}>Add Answer</Button>
        </Col>
      </Row>
    </div>
  );
};

export default QuestionUI;

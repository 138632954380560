export class LoginIntent {
  private readonly _status: number = 401;
  private readonly _token: string = 'AAA';

  constructor (status: number, token: string) {
    this._status = status;
    this._token = token;
  }

  get status (): number {
    return this._status;
  }

  get token (): string {
    return this._token;
  }
}

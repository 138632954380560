import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import SurveyList from 'ui/dashboard/SurveyList';
import CESurvey from 'ui/dashboard/CESurvey';
import ViewSurvey from './ViewSurvey';
import { sessionStorage } from 'core/infrastructure/instances';
import './style.css';

const Dashboard: React.FC = () => {
  const logout = () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <Router>
      <Container>

        {/* <Menu /> */}
        <Navbar bg="light" expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">Surveys ADMIN</Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <a onClick={logout} className="btn">Logout</a>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Switch>
          <Route exact path='/' component={SurveyList} />
          <Route exact path='/create-survey' component={CESurvey} />
          <Route exact path='/edit-survey/:surveyId' component={CESurvey} />
          <Route exact path='/view-survey/:surveyId' component={ViewSurvey} />
        </Switch>
      </Container>
    </Router>
  );
};

export default Dashboard;

import { SurveysService } from 'core/domain/SurveysService';
import { SurveyResult } from '../domain/SurveyResult';

export default class GetSurveyResults {
  private service: SurveysService;

  constructor (service: SurveysService) {
    this.service = service;
  }

  async execute (surveyId: string, language: string): Promise<SurveyResult> {
    return this.service.getSurveyResults(surveyId, language);
  }
}

import { SurveysService } from 'core/domain/SurveysService';

export default class DeleteSurvey {
  private service: SurveysService;

  constructor (service: SurveysService) {
    this.service = service;
  }

  async execute (surveyId: string): Promise<boolean> {
    const response: Record<string, any> = await this.service.deleteSurveys(surveyId);
    if (response.data.status == 200) { return true; } else throw new Error();
  }
}
